import { Component, Input } from '@angular/core';

@Component({
  selector: 'crownx-action-button',
  templateUrl: './action-button.component.html'
})
export class ActionButtonComponent {

	/** The label to display on the button when the action is being performed. */
	@Input()
	public ActiveActionDescriptor: string = '';

	/** The label to display on the button when the action is not being performed. */
	@Input()
	public InactiveActionDescriptor: string = '';

	/** The boolean that indicates if the action is being performed. */
	@Input()
	public IsActionBeingPerformed: boolean = false;

	/** The boolean that indicates if the button should be disabled. */
	@Input()
	public IsButtonDisabled: boolean = false;

	/** The hover text for the button. Default to none. */
	@Input()
	public HoverText: string = '';

	/** The color of the button (technically the style used to apply the color). Default to crown-blue. */
	@Input()
	public ButtonColor: string = 'crown-blue-button';

	/** Custom class(es) that can be applied to the button. Classes which are passed in here should be accessible globally or added to this component's css file. */
	@Input()
	public CustomClass: string = '';

	/** Icon identifier to use for the button using material-symbols-outlined library. */
	@Input()
	public Icon: string = '';
}
