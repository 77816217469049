<div [class.visible-dialog]="IsVisible" class="dialog">
	<div *ngIf="DialogTitle" class="dialog-title crown-blue-background">{{ DialogTitle }}</div>
	<div class="dialog-padding">
		<ng-content></ng-content>
	</div>
	<div *ngIf="ShowDefaultButtons" class="dialog-buttons">
		<button class="dialog-cancel-button" (click)="OnCancelButtonClick()">{{ CancelText }}</button>
		<crownx-action-button (click)="OnOkButtonClick()"
			[ActiveActionDescriptor]="OkLoadingText"
			[InactiveActionDescriptor]="OkText"
			[IsButtonDisabled]="!IsFormValid || IsOkLoading"
			[IsActionBeingPerformed]="IsOkLoading">
		</crownx-action-button>
	</div>
</div>