import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogComponent } from './dialog/dialog.component';
import { OverlayComponent } from './overlay/overlay.component';
import { OverlayShadowModule } from '../overlay-shadow/overlay-shadow.module';
import { ActionButtonModule } from '../common/action-button/action-button.module';

/**
 * This module declared the reusable dialog for Crown X.
 */
@NgModule({
	declarations: [
		DialogComponent,
		OverlayComponent
	],
	imports: [
		CommonModule,
		OverlayShadowModule,
		ActionButtonModule
	],
	exports: [
		DialogComponent,
		OverlayComponent
	]
})
export class DialogModule { }